import { imageUrlAdapter } from '@/adapters/constructor/utils/image';

const speakerAdapter = (data) => {
  return {
    id: data.id,
    image: imageUrlAdapter(data.speaker_avatar?.image),
    name: data.speaker_name,
    company: data.speaker_company,
    position: data.speaker_position,
  };
};

const itemAdapter = ({ data }) => {
  return {
    id: data.id,
    name: data.name,
    time: data.time,
    speakers: data.speakers?.map(speakerAdapter),
  };
};

export default ({ data, items }) => {
  return {
    name: data.name,
    items: items?.map(itemAdapter),
  };
};
